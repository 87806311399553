<template>
  <div class="box has-background-white advertising-card mt-3">
    <div class="image-container is-flex is-justify-content-center">
      <img :src="advertisingData.image" />
    </div>
    <h1 class="titleAdvertising mt-2 text-ellipsis-title">
      {{ advertisingData.title }}
    </h1>
    <p class="text-ellipsis-text paragraph">
      {{ advertisingData.description }}
    </p>
    <button
      v-if="advertisingData.type === 'event'"
      class="
        button
        buttonDataAdvertising
        is-small is-fullwidth
        mt-4
        is-primary-profile is-outlined
      "
      @click="$router.push({ name: 'Events' })"
    >
      Ir a evento
    </button>
    <button
      v-else-if="advertisingData.type === 'announcement'"
      class="
        button
        buttonDataAdvertising
        is-small is-fullwidth
        mt-4
        is-primary-profile is-outlined
      "
      @click="$router.push({ name: 'Job' })"
    >
      Ir a convocatoria
    </button>
    <hr v-if="isActionButtonsOn" />
    <div
      v-if="isActionButtonsOn"
      class="is-flex is-justify-content-space-between mt-3"
    >
      <button
        class="
          button
          buttonDataAdvertising
          is-small is-primary-profile is-outlined
        "
        @click="deleteAdvertising(advertisingData.id)"
      >
        Eliminar
      </button>
      <button
        class="button buttonDataAdvertising is-small is-primary-profile"
        @click="
          $router.push({
            name: 'EditAdvertising',
            params: { id: advertisingData.id },
          })
        "
      >
        Editar
      </button>
    </div>
  </div>
</template>

<script>
import advertisingService from "@/services/Advertising";

export default {
  name: "AdvertisingCard",
  props: {
    advertisingData: {
      required: true,
    },
    isActionButtonsOn: {
      default: false,
    },
  },
  methods: {
    deleteAdvertising(advertisingId) {
      this.$buefy.dialog.confirm({
        title: "Eliminar publicidad",
        message: "¿Estás seguro que deseas eliminar esta publicidad?",
        type: "is-primary-profile",
        cancelText: "Cancelar",
        confirmText: "Eliminar",
        size: "is-small",
        onConfirm: async () => {
          try {
            this.$store.dispatch("fetchLoading", true);
            await advertisingService.deleteAdvertising(advertisingId);

            this.$buefy.toast.open({
              duration: 5000,
              message: "Publicidad eliminada exitosamente",
              position: "is-bottom",
              type: "is-success",
            });
            this.$emit("getAllAdvertising");
          } catch (e) {
            this.$buefy.toast.open({
              duration: 5000,
              message: e.response.data.message,
              position: "is-bottom",
              type: "is-danger",
            });
          }
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.advertising-card {
  height: auto;
  margin: 0 auto;

  .titleAdvertising {
    font-size: 20px;
    font-family: "Roboto", sans-serif;
    color: #353535;
    font-weight: 500;
  }
  hr {
    height: 1px !important;
    margin: 5px 0px;
    background-color: #d5d5d5;
  }

  .image-container {
    width: 100%;
  }

  img {
    height: 150px;
  }
  .text-ellipsis-text {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .text-ellipsis-title {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .paragraph {
    min-height: 45px;
    font-size: 15px;
    font-family: "Roboto", sans-serif;
    color: #7a7a7a;
    font-weight: 400;
  }
  .buttonDataAdvertising {
    border-radius: 8px;
    font-size: 0.8rem
  }
}
</style>
