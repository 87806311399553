import { render, staticRenderFns } from "./ViewAllAdvertising.vue?vue&type=template&id=27f30673&scoped=true&"
import script from "./ViewAllAdvertising.vue?vue&type=script&lang=js&"
export * from "./ViewAllAdvertising.vue?vue&type=script&lang=js&"
import style0 from "./ViewAllAdvertising.vue?vue&type=style&index=0&id=27f30673&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27f30673",
  null
  
)

export default component.exports