<template>
<div class="profile">
  <div class="container py-5 px-3">
    <div class="is-flex is-justify-content-space-between">
      <b-breadcrumb
        align="is-left"
        size="is-small"
      >
        <b-breadcrumb-item tag='router-link' to="/Perfil">Perfil</b-breadcrumb-item>
        <b-breadcrumb-item tag='router-link' to="/ViewAllAdvertising" active> Publicidad</b-breadcrumb-item>
      </b-breadcrumb>
      <button class="button is-primary-profile is-small" @click="$router.push({name: 'NewAdvertising'})"> Crear
        nuevo
      </button>
    </div>
    <div class="columns is-multiline">
      <div v-for="(item, index) in allAdvertising" :key="index" class="column is-4">
        <advertising-card @getAllAdvertising="getAllAdvertising" :advertising-data="item" :is-action-buttons-on="true"></advertising-card>
      </div>
    </div>
  </div>

</div>
</template>

<script>
import advertisingService from "@/services/Advertising";
import AdvertisingCard from "@/components/AdvertisingCard/AdvertisingCard";

export default {
  name: "ViewAllAdvertising",
  components:{
    AdvertisingCard
  },
  data(){
    return{
      allAdvertising: []
    }
  },
  methods:{
    async getAllAdvertising() {
      try {
        this.allAdvertising = await advertisingService.getAllAdvertising();
      } catch (error) {
        console.warn("No history for User", error);
      }
    },
  },
  mounted() {
    this.getAllAdvertising();
  },
}
</script>

<style scoped lang="scss">
.profile {
  background: url(../../assets/img/bg_perfil.png) center no-repeat fixed;
  background-size: cover;
  min-height: 100%;
  width: 100vw;
  min-height: 75vh;
}
</style>
